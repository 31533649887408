import { createSlice } from '@reduxjs/toolkit';
import Axios from '../../utils/axiosInstance';

const initialState = {
  DiamondList: [],
  GemstoneList: [],
  diamondId: [],
  gemstoneId: [],
  unmountId: [],
  UnmountList: [],
  StockBydesignId: [],
  SetmountList: [],
  SetmountId: [],
  SetStockId: [],
  unmount: null,
  setmount: null,
  diamond: null,
  gemstone: null,
  isLoading: false,
  error: null
};

const slice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // INVENTORY DIAMONDS
    createDiamondInventory(state, action) {
      state.isLoading = false;
      state.diamond = action.payload;
    },
    inventoryDiamondList(state, action) {
      state.isLoading = false;
      state.DiamondList = action.payload;
    },
    diamondById(state, action) {
      state.isLoading = false;
      state.diamondId = action.payload;
    },
    // INVENTORY GEMSTONES
    createGemstoneInventory(state, action) {
      state.isLoading = false;
      state.gemstone = action.payload;
    },
    inventoryGemstoneList(state, action) {
      state.isLoading = false;
      state.GemstoneList = action.payload;
    },
    gemstoneById(state, action) {
      state.isLoading = false;
      state.gemstoneId = action.payload;
    },
    // INVENTORY UNSETMOUNT
    createUnmountInventory(state, action) {
      state.isLoading = false;
      state.unmount = action.payload;
    },
    getInventoryUnmount(state, action) {
      state.isLoading = false;
      state.UnmountList = action.payload;
    },
    getUnmountById(state, action) {
      state.isLoading = false;
      state.unmountId = action.payload;
    },
    getstockBydesignId(state, action) {
      state.isLoading = false;
      state.StockBydesignId = action.payload;
    },
    // INVENTORY SETMOUNT
    createSetmountInventory(state, action) {
      state.isLoading = false;
      state.setmount = action.payload;
    },
    getInventorySetmount(state, action) {
      state.isLoading = false;
      state.SetmountList = action.payload;
    },
    SetmountById(state, action) {
      state.isLoading = false;
      state.SetmountId = action.payload;
    },
    SetmountStockId(state, action) {
      state.isLoading = false;
      state.SetStockId = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { GemstoneList, UnmountList, DiamondList, diamond, deleteMasterCosts, createmasterCosts } = slice.actions;

// ----------------------------------  Create Inventory Diamond ------------------------------------

export function CreateDiamondInventory(diamond) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/inventory-diamonds', diamond);
      return dispatch(slice.actions.createDiamondInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------  Update Inventory Diamond ------------------------------------

export function UpdateDiamondInventory(diamond) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/inventory-diamonds/${diamond.id}`, diamond);
      return dispatch(slice.actions.createDiamondInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------- Inventory Diamond List -------------------------------------

export function getInventoryDiamond() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/inventory-diamonds');

      dispatch(slice.actions.inventoryDiamondList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory Diamond Id wise List -------------------------------------

export function getDiamondById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory-diamonds/${id}`);
      dispatch(slice.actions.diamondById(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------  Create Inventory Gemstone ------------------------------------

export function CreateGemstoneInventory(gemstone) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/inventory_gemstones', gemstone);
      return dispatch(slice.actions.createGemstoneInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------  Update Inventory Gemstone ------------------------------------

export function UpdateGemstoneInventory(gemstone) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/inventory_gemstones/${gemstone.id}`, gemstone);
      return dispatch(slice.actions.createGemstoneInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------- Inventory Gemstone List -------------------------------------

export function getInventoryGemstone() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/inventory_gemstones');

      dispatch(slice.actions.inventoryGemstoneList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory Gemstone Id wise List -------------------------------------

export function getGemstoneById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory_gemstones/${id}`);
      dispatch(slice.actions.gemstoneById(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------  Create Inventory Unset mount ------------------------------------

export function CreateUnmountInventory(unmount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/inventory-unmount', unmount);
      console.log('inventory create', response.data.data);
      return dispatch(slice.actions.createUnmountInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------  Update Inventory Unset mount ------------------------------------

export function UpdateUnmountInventory(unmount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/inventory-unmount/${unmount.id}`, unmount);
      return dispatch(slice.actions.createUnmountInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------- Inventory Unset mount List -------------------------------------

export function getInventoryUnmount() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/inventory-unmount');

      dispatch(slice.actions.getInventoryUnmount(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory Unset mount Id wise List -------------------------------------

export function getUnmountById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory-unmount/${id}`);
      dispatch(slice.actions.getUnmountById(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory Unset mount design id wise stock no generate  -------------------------------------

export function fetchStockByDesignId(designId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory-unmount/max-stock/${designId}`);
      dispatch(slice.actions.getstockBydesignId(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------  Create Inventory set mount ------------------------------------

export function CreateSetmountInventory(unmount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/inventory-setmount', unmount);
      console.log('inventory set mount create', response.data.data);
      return dispatch(slice.actions.createSetmountInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------  Update Inventory set mount ------------------------------------

export function UpdateSetmountInventory(unmount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/inventory-setmount/${unmount.id}`, unmount);
      return dispatch(slice.actions.createSetmountInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------- Inventory set mount List -------------------------------------

export function getInventorySetmount() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/inventory-setmount');

      dispatch(slice.actions.getInventorySetmount(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory set mount Id wise List -------------------------------------

export function getSetmountyId(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory-setmount/${id}`);
      dispatch(slice.actions.SetmountById(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory set mount design id wise stock no generate  -------------------------------------

export function getSetmountStockId(designId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory-setmount/max-stock/${designId}`);
      dispatch(slice.actions.SetmountStockId(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
