import PropTypes from 'prop-types';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Box, CardActionArea, Drawer, Stack, Tooltip, IconButton } from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// routes
import { MHidden } from '../../components/@material-extend';
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
import getSidebarConfig from './SidebarConfig';
import { ManufactureCountList } from '../../redux/slices/manufacture';
import {
  getInventoryDiamond,
  getInventoryGemstone,
  getInventoryUnmount,
  getInventorySetmount
} from '../../redux/slices/inventory';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

// ----------------------------------------------------------------------

IconCollapse.propTypes = {
  onToggleCollapse: PropTypes.func,
  collapseClick: PropTypes.bool
};

function IconCollapse({ onToggleCollapse, collapseClick }) {
  return (
    <IconButton
      onClick={onToggleCollapse}
      sx={{
        width: 25,
        height: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        border: '1px solid',
        borderColor: 'currentColor',
        transition: (theme) =>
          theme.transitions.create('all', {
            duration: theme.transitions.duration.shortest
          }),
        ...(collapseClick && {
          bgcolor: 'action.selected'
        })
      }}
    >
      {collapseClick ? <ChevronRight /> : <ChevronLeft />}
    </IconButton>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const manufactureCount = useSelector((state) => state.manufacture.manufactureCount);
  const { DiamondList, GemstoneList, UnmountList, SetmountList } = useSelector((state) => state.inventory);
  const filteredDiamonds = DiamondList.filter((item) => [1, 2, 3].includes(item.status));
  const filteredGemstones = GemstoneList.filter((item) => [1, 2, 3].includes(item.status));
  const filteredUnmount = UnmountList.filter((item) => [1, 2].includes(item.status));
  const filteredSetmount = SetmountList.filter((item) => [1, 2].includes(item.status));
  const sidebarConfig = getSidebarConfig(
    manufactureCount,
    filteredDiamonds,
    filteredGemstones,
    filteredUnmount,
    filteredSetmount
  );
  const count = 1;

  useEffect(() => {
    dispatch(ManufactureCountList(count));
    dispatch(getInventoryDiamond());
    dispatch(getInventoryGemstone());
    dispatch(getInventoryUnmount());
    dispatch(getInventorySetmount());
  }, [dispatch]);

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box
            component={RouterLink}
            to="/"
            sx={{
              display: 'inline-flex',
              textDecoration: 'none',
              ml: 2,
              // color: 'black',
              letterSpacing: '0.2em'
            }}
          >
            {isCollapse ? <Logo /> : 'FLAWLESS INVOICE SYSTEM'}
          </Box>

          <MHidden width="lgDown">
            {!isCollapse && <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
          </MHidden>
        </Stack>
      </Stack>

      <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />

      <Box sx={{ flexGrow: 1 }} />

      {!isCollapse && (
        <Stack spacing={3} alignItems="center" sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center' }} />
      )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
